import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { QuestionCircleOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons-vue';
import { cloneDeep, difference } from 'lodash-es';
import { getGroup, addGroup, setGroup, deleteGroup, queryGroupEmployee, queryNoGroupEmployee, addGroupEmployee, deleteGroupEmployee, groupQuery, groupModify } from '@/api/role';
import jurisdictionSetModal from './components/jurisdictionSetModal.vue';
import { getMenuTree, treeForeach } from '@/utils/setMenuTree';
import Cookies from "js-cookie"; //表头

const columns = [{
  title: '用户组名称',
  dataIndex: 'groupName',
  slots: {
    customRender: 'groupName'
  }
}, {
  title: '用户组描述',
  dataIndex: 'describes',
  slots: {
    customRender: 'describes'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const leftTableColumns = [{
  dataIndex: 'title',
  title: '姓名'
}, {
  dataIndex: 'post',
  title: '岗位'
}];
const rightTableColumns = [{
  dataIndex: 'title',
  title: '姓名'
}, {
  dataIndex: 'post',
  title: '岗位'
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    EditOutlined,
    CheckOutlined,
    jurisdictionSetModal
  },

  setup() {
    //新建组
    const addPostVisible = ref(false);

    const addPost = () => {
      addPostVisible.value = true;
    };

    const formState = reactive({
      groupName: undefined,
      describes: undefined
    });
    const formRef = ref();

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        // console.log('values', formState);
        const param = {
          groupName: formState.groupName,
          describes: formState.describes
        };
        let res = await addGroup(param);

        if (res.code == 200) {
          _message.success('添加成功！');

          addPostVisible.value = false;
          formRef.value.resetFields();
          getTableData();
        } else {
          _message.error(res.message);

          addPostVisible.value = true;
        }
      }).catch(error => {
        console.log('error', error);
      });
    };

    const handleCancel = () => {
      addPostVisible.value = false;
      formRef.value.resetFields();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); // //分页信息
    // const {
    //   paginationConfig, total, current, pageSize
    // } = useTablePagination()
    // //点击分页
    // const onPageChange = (pagination) => {
    //   current.value = pagination.current
    //   // getTableData()
    // }
    //表格行编辑

    const editableData = reactive({});

    const edit = key => {
      editableData[key] = cloneDeep(tableData.value.filter(item => key === item.key)[0]);
      editableData.isShow = true;
    };

    const save = async key => {
      Object.assign(tableData.value.filter(item => key === item.key)[0], editableData[key]);

      if (editableData[key].groupName === "") {
        _message.error('用户组名称不能为空！');

        return;
      }

      let res = await setGroup({
        groupNo: key,
        groupName: editableData[key].groupName
      });

      if (res.code === 200) {
        delete editableData[key];

        _message.success('修改成功！');
      } else {
        _message.error(res.message);
      }
    };

    const editableData1 = reactive({});

    const edit1 = key => {
      editableData1[key] = cloneDeep(tableData.value.filter(item => key === item.key)[0]);
      editableData1.isShow = true;
    };

    const save1 = async key => {
      Object.assign(tableData.value.filter(item => key === item.key)[0], editableData1[key]);

      if (editableData1[key].describes === "") {
        _message.error('用户组描述不能为空！');

        return;
      }

      let res = await setGroup({
        groupNo: key,
        describes: editableData1[key].describes
      });

      if (res.code === 200) {
        delete editableData1[key];

        _message.success('修改成功！');
      } else {
        _message.error(res.message);
      }
    }; //查看人员


    const peopleVisible = ref(false);
    const leftData = ref([]);
    const rigthData = ref([]);
    const mockData = ref([]);
    const originTargetKeys = ref([]);
    const groupNo = ref('');

    const toViewPeople = key => {
      Promise.all([getLeftData(), getRigthData(key)]).then(res => {
        console.log('Promise.all', res);

        try {
          leftData.value = [];
          rigthData.value = [];
          res[0].forEach(item => {
            leftData.value.push({
              key: item.userNo,
              title: item.userName,
              post: item.jobName
            });
          });
          res[1].forEach(item => {
            rigthData.value.push({
              key: item.userNo,
              title: item.userName,
              post: item.jobName
            });
          });
          mockData.value = leftData.value.concat(rigthData.value);
          originTargetKeys.value = rigthData.value.map(item => item.key);
          nextTick(() => {
            peopleVisible.value = true;
            groupNo.value = key;
          });
        } catch (error) {
          console.log(error);
        }
      });
    };

    const targetKeys = ref(originTargetKeys);
    const leftColumns = ref(leftTableColumns);
    const rightColumns = ref(rightTableColumns);

    const onChange = async (nextTargetKeys, direction, moveKeys) => {
      // targetKeys.value = nextTargetKeys;
      // console.log('nextTargetKeys', nextTargetKeys,direction, moveKeys)
      let res = {};
      const param = {
        groupNo: groupNo.value,
        userList: []
      };
      moveKeys.forEach(item => {
        param.userList.push({
          userNo: item
        });
      });

      if (direction == 'right') {
        res = await addGroupEmployee(param);
      } else {
        res = await deleteGroupEmployee(param);
      }

      if (res.code == 200) {
        _message.success('操作成功！');

        targetKeys.value = nextTargetKeys;
      } else {
        _message.error('操作失败！');
      }
    };

    const getRowSelection = ({
      disabled,
      selectedKeys,
      onItemSelectAll,
      onItemSelect
    }) => {
      return {
        getCheckboxProps: item => ({
          disabled: disabled || item.disabled
        }),

        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows.filter(item => !item.disabled).map(({
            key
          }) => key);
          const diffKeys = selected ? difference(treeSelectedKeys, selectedKeys) : difference(selectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },

        onSelect({
          key
        }, selected) {
          onItemSelect(key, selected);
        },

        selectedRowKeys: selectedKeys
      };
    }; //左边数据


    const getLeftData = () => {
      return new Promise((resolve, reject) => {
        queryNoGroupEmployee().then(res => {
          resolve(res.data.employeeList);
        });
      }).catch(error => {
        console.log(error);
        reject(error.data);
      });
    }; //右边数据


    const getRigthData = key => {
      return new Promise((resolve, reject) => {
        queryGroupEmployee({
          groupNo: key
        }).then(res => {
          resolve(res.data.employeeList);
        });
      }).catch(error => {
        console.log(error);
        reject(error.data);
      });
    }; //提交用户组人员
    // const handleOkForPeople = () => {
    // }
    //删除


    const onDelete = async key => {
      let res = await deleteGroup({
        groupNo: key
      });

      if (res.code === 200) {
        _message.success('删除成功！');

        getTableData();
      } else {
        _message.error('删除失败！');
      }
    }; //权限配置


    const jusVisible = ref(false);
    const keyValue = ref([]);

    const tocConf = async key => {
      keyValue.value = [];
      let res = await groupQuery({
        groupNo: key
      });

      try {
        if (res.code === 200 && res.data.menuList.length) {
          const treeData = getMenuTree(res.data.menuList, 2);
          treeForeach(treeData, node => {
            keyValue.value.push(node.key);
          }); // console.log(keyValue.value )
        } else {
          keyValue.value = [];
        }

        nextTick(() => {
          jusVisible.value = true;
          groupNo.value = key;
        });
      } catch (error) {
        console.log('查询岗位权限菜单', error);
      }
    };

    const updateValue = val => {
      // console.log(val)
      keyValue.value = val;
    };

    const isOk = async val => {
      const param = {
        groupNo: groupNo.value,
        menuList: val
      }; // console.log('param',param)

      let res = await groupModify(param);

      if (res.code == 200) {
        _message.success('操作成功！');

        jusVisible.value = false;
      } else {
        _message.error('操作失败！');
      }
    }; //关闭权限配置弹窗


    const closeJusVisible = val => {
      jusVisible.value = val;
    }; //查询表格数据


    const getTableData = async () => {
      loading.value = true;
      let res = await getGroup({});

      try {
        tableData.value = [];

        if (res.data.groupList.length) {
          res.data.groupList.forEach(item => {
            tableData.value.push({
              key: item.groupNo,
              groupName: item.groupName,
              describes: item.describes
            });
          });
        }

        loading.value = false;
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
    };

    const trim = (key, num) => {
      if (num === 1) {
        editableData[key].groupName = editableData[key].groupName.replace(/\s/g, ""); // 去除字符串全部空格
      }

      if (num === 2) {
        editableData1[key].describes = editableData1[key].describes.replace(/\s/g, ""); // 去除字符串全部空格
      }
    }; //判断是否是总站及公司管理员


    const isAdmin = computed(() => {
      if (Cookies.get('isCompanyAdmin') == '1' && Cookies.get('belongProject') == '10000') {
        return true;
      } else if (Cookies.get('isCompanyAdmin') == '1' && Cookies.get('belongProject') != '10000') {
        return false;
      } else if (Cookies.get('isCompanyAdmin') == '0') {
        return true;
      }
    });
    onMounted(() => {
      getTableData();
    });
    return {
      addPost,
      // paginationConfig,
      columns,
      tableData,
      loading,
      // onPageChange,
      tocConf,
      toViewPeople,
      onDelete,
      editableData,
      edit,
      save,
      editableData1,
      edit1,
      save1,
      addPostVisible,
      handleOk,
      formState,
      formRef,
      handleCancel,
      peopleVisible,
      mockData,
      targetKeys,
      leftColumns,
      rightColumns,
      onChange,
      getRowSelection,
      // handleOkForPeople
      jusVisible,
      keyValue,
      updateValue,
      isOk,
      closeJusVisible,
      trim,
      isAdmin
    };
  }

});